import React from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {
    addStop,
    addStopAtIndex,
    editStop,
    pushStopAtIndex,
    updateDestLocation,
    updatePickupLocation,
    updateCityOfStop,
    updateRideDistance,
    updateRideDuration,
    addFirstStop,
    fetchLocationByLatLong,
    updateDestCity,
    updateRideStartDate,
    updateStartTime,
    updateRideEndDate,
    updateEndTime,
    updateTripType,
    removeLastStop,
    updateRentalType,
    updateLeadTime,
    updateCurrentDateTime,
    updatePickupCity
} from '../actions/rideActions';

import {
    flushCarsState
} from '../actions/carsActions'
import Moment from 'moment';

import AniLink from "gatsby-plugin-transition-link/AniLink";
import BlackOverlay from './appComponents/BlackOverlay';
import SliderModal from './appComponents/SliderModal';
// import HeroImage from '../assets/img/car-and-man-on-a-cliff.png';
import Row from './Row.js';
import { Tween } from 'react-gsap';
import { css} from 'emotion';
import {Transition, animated} from 'react-spring/renderprops';

import RentingChoiceButton from './appComponents/RentingChoiceButton';
import RentingChoiceTab from './appComponents/RentingChoiceTab';
import PlacesModal from './appComponents/PlacesModal';
import DateTime from './appComponents/DateTime';
import OSCabIcon from '../assets/img/illustrations/outstationRentals.svg';
import SDCabIcon from '../assets/img/illustrations/selfDrivenRentals.svg';
import ATCabIcon from '../assets/img/illustrations/airportTransfer.svg';
import HRCabIcon from '../assets/img/illustrations/hourlyRentals.svg';
import fancyCurvesDown from '../assets/img/fancy-curves-divider-down.svg';
// import HeroImage from '../assets/img/road-trip-banner.jpg';
import HeroImage from "../assets/img/city-car.jpg";

let DateCountdown= null;

if(typeof window !== `undefined`){
    DateCountdown = require('react-date-countdown-timer').default;
}

/* global tw */

const Section = styled.section`
    ${tw`w-screen relative z-0`};
    background: rgba(0,0,0,1);
    min-height: 50rem;
    height: auto;
    perspective: 3000px;
    ::after{
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        bottom: -9.9rem;
        height: 10rem;
        background-image: url(${fancyCurvesDown});
        background-repeat: no-repeat;
        background-size: contain;
        z-index: 0;
        @media (min-width: 992px){
            background-size: cover;
        }
    }
`

const ParallaxBgHolder = styled.div`
    ${tw`absolute z-back h-screen w-full pin-x`};
    top: -20%;
    left: 0;
    background-image: url(${HeroImage});
    background-repeat: no-repeat;
    background-position: 50% 0%;
    background-size: cover;
    @media (min-width: 992px){
        top: -6%;
        background-position: center; 
        background-size: cover;
    }

    ::after{
        content: "";
        position: relative;
        display: block;
        z-index: 1;
        ${tw`h-screen w-full pin-t pin-l`};
        background-image: linear-gradient(to bottom, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.2) 85%, rgba(0,0,0,1) 100%);
        background-blend-mode: multiply;
        -webkit-background-blend-mode: multiply;
        -moz-background-blend-mode: multiply;
        -o-background-blend-mode: multiply;
    }
`
const MainHeading = styled.h2`
    ${tw`hidden lg:flex items-center justify-between lg:w-full font-bold text-white my-0 w-2/3`};
    margin-top: 20vh;
    letter-spacing: -0.03125rem;
    line-height: 1;
    font-size: ${1.618 + (1.618/2)}rem;
    position: relative;
    left: 3px;
    @media (min-width: 992px){
        font-size: ${2*1.618}rem !important;
    }
    text-shadow: 0 10px 40px rgba(0,0,0,0.2);
    transform: translate3d(0,0,20px);

`
const SubTitle = styled.h4`
    ${tw`font-medium lg:flex hidden`};
    letter-spacing: -${(1/16) * 0.18}rem;
    color: white;
    margin-top: 1rem;
    @media (min-width: 992px){
        line-height: 1.1;
        font-size: 1.502rem;
        opacity: 0.77;
        color: white;
    }
`

const FormHolder = styled.div`
    ${tw`block relative z-20`};
    margin-top: 15rem;
    @media(min-width: 992px){
        // margin-top: 33vh;
        margin-top: 14vh;
    }
`

const FormWrapper = styled.div`
    ${tw`relative z-20 w-full flex flex-wrap bg-white lg:rounded-lg lg:mt-4 lg:px-4 py-8 overflow-hidden`};
    min-height: 12rem;
    transition: all 0.3s ease-in-out;
    border-radius: 0 0 16px 16px;
    ::before{
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        height: 120%;
        width: 120%;
        top: 0;
        left: -10%;
        background: rgba(255,255,255,0.7);
        // opacity: 0.5;
        // filter: blur(20px);

    }

`
const DestinationWrapper = styled.div`
    ${tw`w-full lg:w-1/2 flex items-center p-3 py-6 hover:bg-grey-light cursor-pointer`};
`
const ColorBullet = styled.div`
    ${tw`inline-flex bg-purple w-auto rounded-full mr-4`};
    height: 2rem;
    width: 2rem;
`
// const TextStuff = styled.div`

// `
const CityName = styled.h3`
    ${tw`m-0 tracking-halfpx`};
    font-size: 1.15rem;
    `
const Location = styled.p`
    ${tw`text-sm font-medium opacity-50 truncate w-3/4`};
    color: #5A5B5C;
`

const BookingInputs = styled(animated.div)`
    ${tw`flex items-center w-full lg:w-auto px-1 py-2 lg:mx-2 my-2 hover:bg-grey-light cursor-pointer`};
    transition: all 0.3s ease-in-out;
    @media (min-width: 992px){
        min-width: 30%;
    }
`


function convertTime12to24(time12h) {
    
  
    let [time,modifier] = time12h.split(' ');
    // alert(time+""+modifier);
    if(modifier === "AM"){
      let [hrs,mins] = time.split(":");
      if(hrs === '12'){
        hrs = '00';
      }
      return hrs + ':' + mins + ":00";
    }
    else if(modifier === "PM"){
      let [hrs,mins] = time.split(":");
      if(Number(hrs) < 12){
        hrs = Number(hrs) + 12;
      }
      return hrs + ':' + mins + ":00";
    }
}


class NewSectionOne extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            text: "",
            isRoundtrip: true,
            isOverlayActive: false,
            isSliderModalActive: false,
            formEditing: false,
            isPlace: false,
            isStartDate: false,
            dateEditing: false,
            isPickupActive: false,
            isDestActive: false,
            pickupAddress: null,
            destinationAddress: null,
            pickupLocation: "",
            destLocation: '',
            pickupCity: '',
            destCity: '',
            pickupPlaces: [],
            destPlaces: [],
            startDate: Moment(),
            endDate: Moment(),
            editStartDate: false,
            editingEndDate: false,
            editingStartDate: false,
            destPlaces: [],
            startDates: [],
            startTimes: [],
            address: '',
            rentalType: 2,
            showHourlyPackageOptions: false,
            rentalPackage:'8 hrs, 80kms',
            editDestination:false,
            editPickup:false,
            distance:0,
            duration:0,
            tagline:'',
            // pageTitle:'Search, Compare & Hire cars from all over India',
            // pageTitle: "Outstation Car rentals",
            pageTitle: "Luxury Car Rentals",
            showPageTitle: false
        }
        this.windowWidth = null;
        this.rentalTypeClick = this.rentalTypeClick.bind(this);
        this.handleRentalTypeChange = this.handleRentalTypeChange.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.toggleTripType = this.toggleTripType.bind(this);
        this.handleStartBooking = this.handleStartBooking.bind(this);
        this.getDistanceBetweenStops = this.getDistanceBetweenStops.bind(this);
    }


    componentWillMount(){
        if (typeof analytics === `undefined`) {
            return;
        }
        // eslint-disable-next-line
        analytics.page('\webapp3.0', { category: 'navigation', label: 'Homepage' });

        let ride = this.props.ride;
        if(ride.endTime === ''){
            let minutes = 30 - (Moment().minute() % 30);
            let minStartDate = Moment().add(2, 'h').add(minutes, 'm').seconds('0');
            let minEndDate = Moment(minStartDate).add(2,'h');
            let endDate = Moment(minEndDate).hours(23).minute(30);
            this.props.dispatch(updateRideStartDate(Moment(minStartDate).format("DD-MM-YYYY")));
            this.props.dispatch(updateStartTime(Moment(minStartDate).format("HH:mm:ss")));
            this.props.dispatch(updateRideEndDate(endDate.format("DD-MM-YYYY")));
            this.props.dispatch(updateEndTime(convertTime12to24(Moment(endDate).format("hh:mm A"))));     
        }
        if(this.props.ride.rentalType===4){
            this.setState({rentalPackage:'2 hrs, 20kms', rentalType: 4});
        }
        else if(this.props.ride.rentalType===5){
            this.setState({rentalPackage:'4 hrs, 40kms', rentalType: 5});
        }
        else if(this.props.ride.rentalType===6){
            this.setState({rentalPackage:'8 hrs, 80kms', rentalType: 6});
        } 
        else if(this.props.ride.rentalType===7){
            this.setState({rentalPackage:'12 hrs, 120kms', rentalType: 7});
        } 
        this.props.dispatch(updateLeadTime(2.26));
    }

    componentDidMount() {
        // if(window.location.href.includes("hyderabad")&&window.location.href.includes("self")&&window.location.href.includes("rental")){
        //     this.setState({tagline:'in Hyderabad'})
        // }
        // else if(window.location.href.includes("hyderabad")&&window.location.href.includes("self")){
        //     this.setState({tagline:'in Hyderabad'})
        // }
        // else if(window.location.href.includes("hyderabad")&&window.location.href.includes("outstation")){
        //     this.setState({tagline:'in Hyderabad'})
        // }
        // else if(window.location.href.includes("bangalore")&&window.location.href.includes("outstation")){
        //     this.setState({tagline:'in Bangalore'})
        // }
        // else if(window.location.href.includes("bangalore")&&window.location.href.includes("self")&&window.location.href.includes("rental")){
        //     this.setState({tagline:'in Bangalore'})
        // }
        // else if(window.location.href.includes("bangalore")&&window.location.href.includes("self")){
        //     this.setState({tagline:'in Bangalore'})
        // }
        // else if(window.location.href.includes("chennai")&&window.location.href.includes("self")&&window.location.href.includes("rental")){
        //     this.setState({tagline:'in Chennai'})
        // }
        // else if(window.location.href.includes("chennai")&&window.location.href.includes("self")){
        //     this.setState({tagline:'in Chennai'})
        // }
        // else if(window.location.href.includes("chennai")&&window.location.href.includes("outstation")){
        //     this.setState({tagline:'in Chennai'})
        // }
        // else if(window.location.href.includes("pune")&&window.location.href.includes("outstation")){
        //     this.setState({tagline:'in Pune'})
        // }
        // else if(window.location.href.includes("pune")&&window.location.href.includes("self")){
        //     this.setState({tagline:'in Pune'})
        // }
        // else if(window.location.href.includes("mumbai")&&window.location.href.includes("self")&&window.location.href.includes("rental")){
        //     this.setState({tagline:'in Mumbai'})
        // }
       

            // this.setState({pageTitle:'Flash Sale! Get â‚¹500 - â‚¹750 OFF on every ride!'})


        this.setState({rentalType: this.props.ride.rentalType})

        this.updateWindowDimensions();

        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);

    }

    updateWindowDimensions(){
        this.setState({ wW: window.innerWidth, wH: window.innerHeight });

    }
    
    rentalTypeClick(type, bool){
        console.log(this.state.wW);
        if(this.state.wW < 992){
            this.props.showRentalOptions();
        }
        else{
            this.handleRentalTypeChange(type, bool);
        }
    }

    handleFormEdit(type) {
        let self = this;
        if (type === 'pickup') {
            this.props.showPlacesModal();
        }
        else if (type === 'dest') {
            this.props.showPlacesModal();
        }
        else if (type === 'startDate') {
            this.setState({
                isOverlayActive: true,
                isSliderModalActive: true,
                editingStartDate: true,
                editingEndDate: false,
                isPickupActive: false,
                isDestActive: false,
            });

        }
        else if (type === 'endDate') {
            this.setState({
                isOverlayActive: true,
                isSliderModalActive: true,
                editingEndDate: true,
                editingStartDate: false,
                isPickupActive: false,
                isDestActive: false,
            });
        }
        else if (type === 'tripType') {
            this.setState({ isRoundtrip: this.state.isRoundtrip }, function () {

                //toggling with destination loc
                if (self.props.ride.dest.loc !== "") {
                    self.toggleTripType();
                }
            });
        }
    }


    toggleTripType() {
        // if(this.state.rentalType === 2){
        //     alert('Round Trip is mandatory for Self Drive Cars');
        //     return;
        // }
        if (this.props.ride.pickup.loc === "") {
            alert('Please enter your pickup address');
            return;
        }
        if (this.props.ride.dest.loc === "" && (this.props.ride.rentalType===1 || this.props.ride.rentalType===3)) {
            alert('Please enter your destination address');
            return;
        }
        let stops = this.props.ride.stops;
        let payLoad = stops[0];
        // console.log(analytics);


        if (this.state.isRoundtrip) {
            // eslint-disable-next-line
            analytics.track('Round Trip', { category: 'clicks' });
            this.props.dispatch(addStop(payLoad));
            this.props.dispatch(updateCityOfStop({ city: payLoad.city, cityLat: payLoad.cityLat, cityLng: payLoad.cityLng }, stops.length));
            this.props.dispatch(updateTripType('Round Trip'));
        }
        else {
            // eslint-disable-next-line
            analytics.track('One Way', { category: 'clicks' });
            this.props.dispatch(removeLastStop())
            this.props.dispatch(updateTripType('One Way'));
        }
    }

    handleRentalTypeChange = (type, bool) => {
        this.props.dispatch(flushCarsState());
        let ride = this.props.ride;
        this.setState({ rentalType: type });
        this.props.dispatch(updateRentalType(type));
        if(bool === true){
            this.props.dispatch(updatePickupLocation({
                name: '',
                locLat: '',
                locLng: '',
            }));
            this.props.dispatch(updatePickupCity({
                city: '',
                cityLat: '',
                cityLng: ''
            }));
            this.props.dispatch(updateDestLocation({
                name: '',
                locLat: '',
                locLng: '',
            }));
            this.props.dispatch(updateDestCity({
                city: '',
                cityLat: '',
                cityLng: ''
            }));
        }
        this.setState({cars: []})
        if (type === 1) {
            this.props.dispatch(updateLeadTime(2.25));
            if (this.props.ride.dest.loc === "" && this.state.isRoundtrip) {
                this.setState({ isRoundtrip: true });
                this.props.dispatch(updateTripType('Round Trip'));
            }
        }
        else if (type === 2) {
            // eslint-disable-next-line
            analytics.track('Self Drive Chosen', { category: 'clicks' });
            this.props.dispatch(updateLeadTime(4));
            this.setState({ isRoundtrip: true });
            // this.setState({ isRoundtrip: true, endDate: Moment(this.state.startDate).add(24, 'h'), endTime: this.state.startTime }, function () {
            //     this.props.dispatch(updateRideEndDate(Moment(this.state.endDate).format('DD-MM-YYYY')));
            //     this.props.dispatch(updateTripType('Round Trip'));
            // });
        }
        else if (type === 3) {
            
            // eslint-disable-next-line
            analytics.track('Airport Type Chosen', { category: 'clicks' });
            this.props.dispatch(updateLeadTime(4));
            this.props.dispatch(updateTripType('One Way'));
            // this.setState({ startDate: Moment().add(4,'h'),endDate: Moment(this.state.startDate).add(24, 'h')},function(){
            //     // this.props.dispatch(updateRideEndDate(Moment(this.state.endDate).format('DD-MM-YYYY')));
            //     // this.props.dispatch(updateTripType('Round Trip'));
            // });
        }
        else if (type >= 4) {
            this.props.dispatch(updateLeadTime(4));
            if (type === 4) {
                // eslint-disable-next-line
                analytics.track('Rental Type 2 Hr 20Kms', { category: 'clicks' });
                this.setState({ isOverlayActive: false, showHourlyPackageOptions: false, rentalPackage: '2 hrs, 20kms', endDate: Moment(this.state.startDate).add(24, 'h') });
            }
            else if (type === 5) {
                // eslint-disable-next-line
                analytics.track('Rental Type 4 Hr 40Kms', { category: 'clicks' });
                this.setState({ isOverlayActive: false, showHourlyPackageOptions: false, rentalPackage: '4 hrs, 40kms', endDate: Moment(this.state.startDate).add(24, 'h') });
            }
            else if (type === 6) {
                // eslint-disable-next-line
                analytics.track('Rental Type 8 Hr 80Kms', { category: 'clicks' });
                this.setState({ isOverlayActive: false, showHourlyPackageOptions: false, rentalPackage: '8 hrs, 80kms', endDate: Moment(this.state.startDate).add(24, 'h') });
            }
            else if (type === 7) {
                // eslint-disable-next-line
                analytics.track('Rental Type 12 Hr 120Kms', { category: 'clicks' });
                this.setState({ isOverlayActive: false, showHourlyPackageOptions: false, rentalPackage: '12 hrs, 120kms', endDate: Moment(this.state.startDate).add(24, 'h') });
            }
        }
    }


    handleStartBooking(event) {
        
        // eslint-disable-next-line
        analytics.track('Booking Started', { category: 'clicks' });
        let now = Moment().format('DD-MM-YYYYÂ HH:mm:ss');
        console.log(now);
        this.props.dispatch(updateCurrentDateTime(now));
        // this.getDistanceBetweenStops();
    }


    getDistanceBetweenStops() {
        
        console.log("Distance called");
        let self = this;
        if (this.props.stops[0]===undefined && this.props.stops.length <= 2 ){
            return;
        }
        let ride = this.props.ride;
        let routepoints = [];
        let pickCity = new window.google.maps.LatLng(ride.pickup.cityLat,ride.pickup.cityLng);
        let pickLoc = new window.google.maps.LatLng(ride.pickup.locLat,ride.pickup.locLng);
       
        let request1 = {
            origin: pickCity,
            destination: pickLoc,
            waypoints: routepoints,
            travelMode: 'DRIVING'
        };

        let waypoints = [];
        let stops = this.props.stops;
        let origin = new window.google.maps.LatLng(stops[0].latitude,stops[0].longitude)
        let destination = new window.google.maps.LatLng(stops[stops.length - 1].latitude,stops[stops.length - 1].longitude)
        for(let i = 1 ; i < stops.length -1 ;i++){
            let waypoint = {location: new window.google.maps.LatLng(stops[i].latitude,stops[i].longitude),stopover: true};
            waypoints.push(waypoint);
        }
        
        let request = {
            origin: origin,
            destination: destination,
            waypoints: waypoints,
            travelMode: 'DRIVING'
        };

        //eslint-disable-next-line
        const DirectionsService = new window.google.maps.DirectionsService();
        
        if(this.props.nearby){
        DirectionsService.route(request1, (result, status) => {
            //eslint-disable-next-line
            console.log(result);
            if (status === "OK") {
                const route = result.routes[0];
                let distance = route.legs.reduce((carry, curr) => {
                    return carry + curr.distance.value;
                    }, 0) / 1000;
                let duration = route.legs.reduce((carry, curr) => {
                    return carry + curr.duration.value;
                    }, 0) / 3600;
                    self.setState({distance:distance,duration:duration})
            }
            else {
                console.error(`error fetching directions ${result}`);
                }
        });
    }
       
        //eslint-disable-next-line
        DirectionsService.route(request, (result, status) => {
            //eslint-disable-next-line
            console.log(result);
            if (status === "OK") {
                const route = result.routes[0];
                let distance = route.legs.reduce((carry, curr) => {
                    return carry + curr.distance.value;
                    }, 0) / 1000;
                let duration = route.legs.reduce((carry, curr) => {
                    return carry + curr.duration.value;
                    }, 0) / 3600;
                    if(stops.length>2){
                        let distance1 = self.state.distance*2;
                        let duration1 =  self.state.duration*2;
                        self.setState({
                            distance: distance1,
                            duration: duration1
                        })
                    }
                self.props.dispatch(updateRideDistance(parseInt(distance+self.state.distance)));
                self.props.dispatch(updateRideDuration(parseInt(duration+self.state.duration)));
            } 
            else {
                console.error(`error fetching directions ${result}`);
                }
        });
    }

    // getDistanceBetweenStops() {
    //     
    //     console.log("Distance called");
    //     let self = this;
    //     if (this.props.stops[0]===undefined && this.props.stops.length <= 2 ){
    //         return;
    //     }
    //     let waypoints = []
    //     let stops = this.props.stops;
    //     let origin = new window.google.maps.LatLng(stops[0].latitude,stops[0].longitude)
    //     let destination = new window.google.maps.LatLng(stops[stops.length - 1].latitude,stops[stops.length - 1].longitude)

    //     for(let i = 1 ; i < stops.length -1 ;i++){
    //         let waypoint = {location: new window.google.maps.LatLng(stops[i].latitude,stops[i].longitude),stopover: true};
    //         waypoints.push(waypoint);
    //     }

    //     let request = {
    //         origin: origin,
    //         destination: destination,
    //         waypoints: waypoints,
    //         travelMode: 'DRIVING'
    //     };

    //     //eslint-disable-next-line
    //     const DirectionsService = new window.google.maps.DirectionsService();
    //     //eslint-disable-nexxt-line
    //     DirectionsService.route(request, (result, status) => {
    //         //eslint-disable-next-line
    //         console.log(result);
    //         if (status === "OK") {
    //             const route = result.routes[0];
    //             let distance = route.legs.reduce((carry, curr) => {
    //                 return carry + curr.distance.value;
    //                 }, 0) / 1000;
    //             let duration = route.legs.reduce((carry, curr) => {
    //                 return carry + curr.duration.value;
    //                 }, 0) / 3600;
    //             self.props.dispatch(updateRideDistance(parseInt(distance)));
    //             self.props.dispatch(updateRideDuration(parseInt(duration)));
    //         } 
    //         else {
    //             console.error(`error fetching directions ${result}`);
    //             }
    //     });
    // }


    componentDidUpdate(prevProps, prevState){
        // console.log("KJAE<FBJKEA<BM");
        if(this.props.ride.leadTime !== prevProps.ride.leadTime ){
            let ride = this.props.ride;
        // this.useGeolocation();
            // let startDates = [], endDates=[];
            let today = Moment();
            let minutes = 30 - (today.minute() % 30);
            let leadTime = Number(this.props.ride.leadTime);
            // let leadTime = 2;
            let minStartDate = Moment().add(leadTime, 'h').add(minutes, 'm').seconds('0');
            let startDate = Moment().add(leadTime, 'h').add(minutes, 'm').seconds('0');
            this.setState({ minStartDate });
            
            if(Moment(ride.startDate, 'DD-MM-YYYY').hours(ride.startTime.split(':')[0]).minutes(ride.startTime.split(':')[1]).diff(Moment(), 'h') < this.props.ride.leadTime){
                this.setState({ startDate, startTime: Moment(startDate).format("hh:mm A") }, function(){
                    this.props.dispatch(updateStartTime(convertTime12to24(this.state.startTime)));
                });
                this.props.dispatch(updateRideStartDate(Moment(startDate).format("DD-MM-YYYY")));
            }
            if(this.props.rentalType === 2 ){
                let minEndDate = Moment(startDate).add(1,'days');
                let endDate = Moment(minEndDate, 'DD-MM-YYYY');
                // console.log(ride.startDate);
                this.setState({ minEndDate });
    
                this.setState({ endDate, endTime: ride.startTime }, ()=>{
                    this.props.dispatch(updateEndTime(ride.startTime));
                    this.props.dispatch(updateRideEndDate(Moment(this.props.ride.startDate,'DD-MM-YYYY').add(1,'days').format("DD-MM-YYYY")));
                });
                ;
            }
            else{
                let minEndDate = Moment(startDate).add(2,'h');
                let endDate = Moment(ride.startDate, 'DD-MM-YYYY').hours(23).minutes(30);
                this.setState({ minEndDate });
    
                this.setState({ endDate, endTime: Moment(endDate).format("hh:mm A") }, function(){
                    this.props.dispatch(updateEndTime(convertTime12to24(this.state.endTime)));
                });
                this.props.dispatch(updateRideEndDate(Moment(endDate).format("DD-MM-YYYY")));    
            }
 
            // if(ride.startDate === Moment().format("DD-MM-YYYY")){
            //     if(Moment(ride.startTime, "HH:mm:ss").diff(Moment(),'h') < this.props.ride.leadTime){
                    
            //     }
            // }
        
            // for(let i=0;i < 100; i++){
            //     startDates.push(Moment(minStartDate).add(i, 'd'));
            // }
            // let startTime = Moment(minStartDate).format("HH:mm");
            // let startTimes = timelineLabels(startTime,30,'m');
            // this.setState({ startDates, startTimes });




            // for(let i=0;i < 100; i++){
            //     endDates.push(Moment(minEndDate).add(i, 'd'));
            // }
            // let endTime = Moment(minEndDate).format("HH:mm");
            // let endTimes = timelineLabels(endTime,30,'m');
            // this.setState({ endDates, endTimes });
        }

        if(prevProps.stops !== this.props.stops && this.props.stops.length > 1){
            this.getDistanceBetweenStops();
        }
    }

    render(){
        let ride = this.props.ride;
        return(
            <Section>
                <Row justifyBetween>
                    <div className={css`${tw`w-full lg:w-2/3 mt-8`}`}>
                        <Tween staggerFrom={{y: 100, opacity: 0, stagger: 2}}>
                            <MainHeading className={css`${tw`text-xl sm:text-xl md:text-2xl lg:text-3xl xl:text-3xl`}`}>
                                {this.state.pageTitle}
                            </MainHeading>
                            <SubTitle>
                            {/* Search, Compare & Save! */}
                            {/* Rent Sanitized cars from Reliable Car Rental companies. */}
                            {/* Verified, Experienced & Trusted hosts */}
                            Luxury Chauffeur-Driven Cars, Your Signature of Style.
                            </SubTitle>
                        </Tween>
                    </div>
                </Row>
                <FormHolder>

                    <Row>
                        {/* <RentingChoiceTab
                            active={this.props.ride.rentalType === 2}
                            type={'SD'}
                            onClick={() => this.rentalTypeClick(2, true)}>
                            Self Drive Cars
                        </RentingChoiceTab> */}
                        <RentingChoiceTab
                            active={this.props.ride.rentalType === 1}
                            type={'OS'}
                            onClick={() => this.rentalTypeClick(1, true)}>
                            Car for Outstation
                        </RentingChoiceTab>
                        <RentingChoiceTab
                            active={this.props.ride.rentalType === 3}
                            type={'AT'}
                            onClick={() => this.rentalTypeClick(3, true)}>
                            Airport Transfer
                        </RentingChoiceTab>
                        <RentingChoiceTab
                            active={this.props.ride.rentalType >= 4}
                            type={'HR'}
                            onClick={() => this.rentalTypeClick(6, true)}>
                            Hourly Rental
                        </RentingChoiceTab>
                    </Row>

                    <Row>
                        <FormWrapper>
                            <Row>

                                <DestinationWrapper onClick={() => this.props.handleFormEdit('pickup')}>
                                    <ColorBullet></ColorBullet>
                                    {this.props.ride.rentalType !== 2 && this.props.ride.rentalType <= 3 ? <div>
                                        <CityName>
                                            {this.props.ride.pickup.loc === '' ?
                                                'Enter Pickup Location' : this.props.ride.pickup.city
                                            }
                                        </CityName>
                                        <Location>{this.props.ride.pickup.loc}</Location>
                                    </div>:
                                    <div>
                                        <CityName>
                                            {!this.props.ride.pickup.hasOwnProperty('city') || this.props.ride.pickup.city === '' ?
                                                'Choose Pickup City' : this.props.ride.pickup.city
                                            }
                                        </CityName>
                                        {/* <Location>{this.props.ride.pickup.loc}</Location> */}
                                    </div>
                                    }
                                </DestinationWrapper>
                                
                                {this.props.ride.rentalType !== 2 && this.props.ride.rentalType < 3 &&
                                
                                    <DestinationWrapper onClick={() => this.props.handleFormEdit('dest')}>
                                        <ColorBullet></ColorBullet>
                                        <div>
                                            <CityName>
                                                {this.props.ride.dest.loc === '' ?
                                                    'Enter Destination Location' : this.props.ride.dest.city
                                                }
                                            </CityName>
                                            <Location>{this.props.ride.dest.loc}</Location>
                                        </div>
                                    </DestinationWrapper>
                                }

                            </Row>
                            
                            <Row justifyBetween className={css`${tw`mt-4`}`}>

                                <div className={css`${tw`flex flex-wrap  w-full lg:w-1/2`}`}>
                                    {/*<BookingInputs onClick={() => this.props.handleFormEdit('startDate') } >

                                        {Moment(ride.startTime, "HH:mm A").format('HH').valueOf() >= 6 && Moment(ride.startTime, "HH:mm A").format('HH').valueOf() <= 18 ?
                                            <img className={css`${tw`inline-flex h-8`}`} src={require('../components/SvgComponents/sunrise.svg')} />
                                            :
                                            <img className={css`${tw`inline-flex h-8`}`} src={require('../components/SvgComponents/sunset.svg')} />
                                        }
                                        <div className={css`${tw`ml-2`}`}>
                                            <p className={css`${tw`text-xs lg:text-sm opacity-50`}`}>Start at</p>
                                            <p className={css`${tw`font-medium text-grey-darker`}`}>{Moment(ride.startDate, "DD-MM-YYYY").format("DD MMM")}, {Moment(ride.startTime, "HH:mm:ss").format("hh:mm A")}</p>
                                        </div>
                                    </BookingInputs>*/}

                                    <div onClick={(e) => { this.handleFormEdit('startDate') }} className={css`${tw`flex items-center w-2/3 lg:w-1/3 px-1 py-2 my-2 hover:bg-grey-light cursor-pointer`}`}>
                                        {Moment(ride.startTime, "HH:mm A").format('HH').valueOf() >= 6 && Moment(ride.startTime, "HH:mm A").format('HH').valueOf() <= 18 ?
                                            <img className={css`${tw`inline-flex h-8`}`} src={require('../components/SvgComponents/sunrise.svg')} />
                                            :
                                            <img className={css`${tw`inline-flex h-8`}`} src={require('../components/SvgComponents/sunset.svg')} />
                                        }
                                        <div className={css`${tw`ml-2`}`}>
                                            <p className={css`${tw`text-xs lg:text-sm opacity-50`}`}>Start at</p>
                                            <p className={css`${tw`font-medium text-grey-darker`}`}>{Moment(ride.startDate, "DD-MM-YYYY").format("DD MMM")}, {Moment(ride.startTime, "HH:mm:ss").format("hh:mm A")}</p>
                                        </div>

                                    </div>

                                    {(this.state.isRoundtrip || this.props.ride.rentalType===2) && this.props.ride.rentalType < 3 && 
                                        <div onClick={(e) => { this.handleFormEdit('endDate') }} className={css`${tw`flex items-center w-2/3 lg:w-1/3 px-1 py-2 my-2 hover:bg-grey-light cursor-pointer`}`}>
                                            {Moment(ride.endTime, "HH:mm A").format('HH').valueOf() >= 6 && Moment(ride.endTime, "HH:mm A").format('HH').valueOf() <= 18 ?
                                                <img className={css`${tw`inline-flex h-8`}`} src={require('../components/SvgComponents/sunrise.svg')} />
                                                :
                                                <img className={css`${tw`inline-flex h-8`}`} src={require('../components/SvgComponents/sunset.svg')} />
                                            }
                                            <div className={css`${tw`ml-2`}`}>
                                                <p className={css`${tw`text-xs lg:text-sm opacity-50`}`}>End at</p>
                                                <p className={css`${tw`font-medium text-grey-darker`}`}>{Moment(ride.endDate, "DD-MM-YYYY").format("DD MMM")}, {Moment(ride.endTime, "HH:mm:ss").format("hh:mm A")}</p>
                                            </div>

                                        </div>}
    
                                       {/* <BookingInputs onClick={() => this.props.handleFormEdit('endDate')}>

                                            {Moment(ride.endTime, "HH:mm A").format('HH').valueOf() >= 6 && Moment(ride.endTime, "HH:mm A").format('HH').valueOf() <= 18 ?
                                                <img className={css`${tw`inline-flex h-8`}`} src={require('../components/SvgComponents/sunrise.svg')} />
                                                :
                                                <img className={css`${tw`inline-flex h-8`}`} src={require('../components/SvgComponents/sunset.svg')} />
                                            }
                                            <div className={css`${tw`ml-2`}`}>
                                                <p className={css`${tw`text-xs lg:text-sm opacity-50`}`}>End at</p>
                                                <p className={css`${tw`font-medium text-grey-darker`}`}>{Moment(ride.endDate, "DD-MM-YYYY").format("DD MMM")}, {Moment(ride.endTime, "HH:mm:ss").format("hh:mm A")}</p>
                                            </div>

                                        </BookingInputs>*/}

                                    

                                </div>
                                        
                                <div className={css`${tw`flex flex-wrap justify-end w-full lg:w-1/2`}`}>
                                    {this.props.ride.rentalType !== 2 && this.props.ride.rentalType < 3 &&

                                        <BookingInputs onClick={() => this.handleFormEdit('tripType')}>

                                            <img className={css`${tw`h-8`}`} src={this.state.isRoundtrip ? require('../components/SvgComponents/roundtrip.svg') : require('../assets/img/oneWay.png')} />
                                            <div className={css`${tw`ml-2`}`}>
                                                <p className={css`${tw`text-xs lg:text-sm opacity-50`}`}>Trip Type</p>
                                                <p className={css`${tw`font-medium text-grey-darker`}`}>
                                                    {this.state.isRoundtrip ? 'Round Trip' : 'One Way'}
                                                </p>
                                            </div>
                                        </BookingInputs>

                                    }
                                    {this.props.ride.rentalType >= 4 &&
                                        
                                        <BookingInputs onClick={() => this.props.handleFormEdit('package')}>

                                            <div className={css`${tw`ml-2`}`}>
                                                <p className={css`${tw`text-xs lg:text-sm opacity-50`}`}>Package</p>
                                                <p className={css`${tw`font-medium text-grey-darker`}`}>
                                                    {/* {this.props.ride.} */}
                                                    {this.props.ride.hourlyPackage}
                                                </p>
                                            </div>
                                        </BookingInputs>

                                    }

                                    {this.props.ride.rentalType === 3 &&

                                        <BookingInputs onClick={() => this.props.handleFormEdit('airportTripType')}>
                                            <div className={css`${tw`ml-2`}`}>
                                                <p className={css`${tw`text-xs lg:text-sm opacity-50`}`}>Trip Type</p>
                                                <p className={css`${tw`font-medium text-grey-darker`}`}>
                                                    {/* {this.props.ride.} */}
                                                    {this.props.ride.airportType}
                                                </p>
                                            </div>
                                        </BookingInputs>
                                    }   
                                    <div onClick={(e) => this.handleStartBooking(e)} 

                                        className={css`${tw`flex items-center w-full lg:w-2/3`};`}>
                                        <AniLink to="/carsList" hex="#8800dd"
                                            entry={{
                                                // delay: 1,
                                                state: { pass: 'Whatever you want', to: 'the next page' },
                                            }}
                                            exit={{
                                                delay: 1,
                                                state: { pass: 'Whatever you want', fromexit: 'the next page' },
                                            }}
                                            className={css`${tw`no-underline text-center text-white font-bold px-18 py-6 w-full rounded-lg `}; background-image: linear-gradient(115deg, #AA0BCA 0%, #7D0EDE 39%)`}>
                                            Search
                                        </AniLink>
                                    </div>
                                </div>
                            </Row>
                        </FormWrapper>
                    </Row>
                </FormHolder>
                <ParallaxBgHolder alt={"zyppys"}/>
                <BlackOverlay onClick={() => this.setState({ isOverlayActive: false, isSliderModalActive: false, showHourlyPackageOptions: false })} active={this.state.isOverlayActive} >
                {(this.state.isDestActive || this.state.isPickupActive) && 
                    <PlacesModal 
                        active={this.state.isSliderModalActive} 
                        isPickup={this.state.isPickupActive} 
                        isDest={this.state.isDestActive} 
                        onClick={()=>this.setState({isOverlayActive: false,isSliderModalActive:false})}
                        showHourlyPackageOptions = {this.state.showHourlyPackageOptions}
                        isRoundtrip = {this.state.isRoundtrip}
                    />
                }
                {(this.state.editingStartDate||this.state.editingEndDate) &&
                    <DateTime active={this.state.isSliderModalActive} isStartDate={this.state.editingStartDate} onClick={()=>this.setState({isOverlayActive: false,isSliderModalActive:false})}/>
                }
                {this.state.showHourlyPackageOptions &&

                    <SliderModal active={this.state.showHourlyPackageOptions}>
                    <div className={css`${tw`w-full flex items-center flex-wrap px-6 py-8`}`}>
                            <h3 className={css`${tw`w-full lg:px-8 font-light lg:text-3xl tracking-tight mb-2 text-grey`}`}>
                                How long do you need the rental? <br />
                            </h3>  
                            <div className={css`${tw`w-full flex items-start lg:px-8`}`}>
                                <ul className={css`${tw`list-reset w-full`}`}>
                                    {/* <li className={css`${tw`font-medium text-grey-dark my-1 lg:text-xl py-2 px-2 hover:bg-grey-light w-full cursor-pointer`}`} onClick={()=>this.handleRentalTypeChange(4)}>2 hrs, 20kms</li> */}
                                    {/* <li className={css`${tw`font-medium text-grey-dark my-1 lg:text-xl py-2 px-2 hover:bg-grey-light w-full cursor-pointer`}`} onClick={()=>this.handleRentalTypeChange(5, false)}>4 hrs, 40kms</li> */}
                                    <li className={css`${tw`font-medium text-grey-dark my-1 lg:text-xl py-2 px-2 hover:bg-grey-light w-full cursor-pointer`}`} onClick={()=>this.handleRentalTypeChange(6, false)}>8 hrs, 80kms</li>
                                    <li className={css`${tw`font-medium text-grey-dark my-1 lg:text-xl py-2 px-2 hover:bg-grey-light w-full cursor-pointer`}`} onClick={()=>this.handleRentalTypeChange(7, false)}>12 hrs, 120kms</li>

                                </ul>
                            </div>
                        </div>

                    </SliderModal>
                }
            </BlackOverlay>
            </Section>
        );
    }
}

const mapStateToProps = (state) => ({
    ride: state.ride,
    stops: state.ride.stops,
    isUserLoggedIn: state.user.isLoggedIn,
    rentalType: state.ride.rentalType,
    nearby: state.ride.nearby,
})

export default connect(mapStateToProps)(NewSectionOne);

// const mapStateToProps = (state) => ({
//     ride: state.ride,
//     stops: state.ride.stops,
//     isUserLoggedIn: state.user.isLoggedIn,
// })


// export default connect(mapStateToProps)(NewSectionOne);